.my-players {
  .col-6:first-child {
    border-right: 1px solid $border-color;
    padding-right: 30px;
  }

  .col-6:last-child {
    padding-left: 30px;
  }

  .my-players-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    h3 {
      font-size: 1.15rem;
      text-transform: uppercase;
    }
  }

  .no-players {
    padding: 50px;
    text-align: center;
    color: $text-faded-color;

    svg {
      font-size: 3rem;
    }

    span {
      display: block;
      margin-top: 10px;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  .trunacate-60 {
    width: 60%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.edit-profile {
  display: flex;
}

.edit-text {
  font-size: 0.6em;
  margin-left: 1.5em;
  color: var(--primary);
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
}

.view-text {
  font-size: 0.6em;
  color: var(--primary);
  margin-left: 1.5em;
}

.view-profile {
  display: flex;
  margin-bottom: 0.6em;
}

.delete-text {
  font-size: 0.6em;
  margin-left: 1.5em;
  text-transform: uppercase;
  font-weight: bold;
  text-align: left;
  cursor: pointer;
}
.view-profile-button {
  background: none;
  border: none;
  padding: 0 0 0 0;
}
.actions-shift {
    display: block !important;
    margin-top: 10px;
  
}