.global-sidebar {
  z-index: 800;
  position: sticky;
  flex-shrink: 0;
  top: 60px;
  width: 225px;
  height: calc(100vh - 60px);
  background: $sidebar-bg-color;
  border-right: 1px solid $header-sidebar-border-color;
  transition: 0.2s ease-in-out;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 5px 3px 5px black;
  &.closed {
    width: 55px;

    a {
      //display: none;
      color: transparent;
      width: 50px;
    }
  }

  a {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 700;
    display: flex;
    align-items: center;
    font-size: 12px;
  }

  ul {
    width: 200px;
    list-style: none;
    margin: 0px;
    padding: 0px;

    li {
      margin: 32px 0px;
      padding-left: 12px;
    }
  }

  hr {
    border: none;
    background-color: $hr-color;
    width: 100%;
    margin: 36px 0px;
    height: 1px;
  }

  .icon {
    margin-right: 12px;
    margin-left: 3px;
  }

  .icon img {
    width: 25px;
    height: 25px;
  }
}

.tippy-box[data-animation="fade"][data-state="hidden"] {
  opacity: 0;
}
[data-tippy-root] {
  max-width: calc(100vw - 10px);
}
.tippy-box {
  position: relative;
  background-color: $sidebar-bg-color;
  border: 1px solid $header-sidebar-border-color;
  color: #fff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  outline: 0;
  transition-property: transform, visibility, opacity;
}
.tippy-box[data-placement^="top"] > .tippy-arrow {
  bottom: 0;
}
.tippy-box[data-placement^="top"] > .tippy-arrow:before {
  bottom: -7px;
  left: 0;
  border-width: 8px 8px 0;
  border-top-color: initial;
  transform-origin: center top;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow {
  top: 0;
}
.tippy-box[data-placement^="bottom"] > .tippy-arrow:before {
  top: -7px;
  left: 0;
  border-width: 0 8px 8px;
  border-bottom-color: initial;
  transform-origin: center bottom;
}
.tippy-box[data-placement^="left"] > .tippy-arrow {
  right: 0;
}
.tippy-box[data-placement^="left"] > .tippy-arrow:before {
  border-width: 8px 0 8px 8px;
  border-left-color: initial;
  right: -7px;
  transform-origin: center left;
}
.tippy-box[data-placement^="right"] > .tippy-arrow {
  left: 0;
}
.tippy-box[data-placement^="right"] > .tippy-arrow:before {
  left: 0px;
  top: 0px;
  /*border-width: 8px 8px 8px 0;
  border-right-color: initial;
  transform-origin: center right;
  box-shadow: 0px 0px 1px 1px $header-sidebar-border-color;*/
  transform-origin: center center;

  width: 10px;
  height: 10px;
  transform: translate(-50%, 29.5%) rotate(-45deg);
  border: 1px solid $header-sidebar-border-color;
  border-right: none;
  border-bottom: none;
  background-color: $sidebar-bg-color;
  z-index: -1;
}
.tippy-box[data-inertia][data-state="visible"] {
  transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
}
.tippy-arrow {
  width: 16px;
  height: 16px;
  color: $sidebar-bg-color;
}
.tippy-arrow:before {
  content: "";
  position: absolute;
  border-color: transparent;
  border-style: solid;
}
.tippy-content {
  position: relative;
  padding: 3px 12px;
  z-index: 1;
}
