* {
  // Fixes bug with slick slider
  min-height: 0;
  min-width: 0;
}

body {
  background-color: $content-bg-color;
  font-family: $font-stack;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //min-width: 1300px;
  overflow-x: scroll;
}
a {
  color: white;
}

// Utilities
.left {
  text-align: left;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.justify {
  text-align: justify;
}

.container-fluid.no-right-padding {
  padding-right: 0px;
}

.faded {
  color: $text-faded-color;
}

h1 {
  font-size: 32px;
  line-height: 39px;
  font-family: $font-stack;
  font-weight: bold;
  color: $h-color;
  text-transform: uppercase;
  padding-bottom: 16px;
}

.outline {
  /*text-shadow: 1px 1px 0px var(--primary), -1px -1px 0px var(--primary), 1px -1px 0px var(--primary), -1px 1px 0px var(--primary);
  color: transparent;*/
  // font-family: "Space Grotesk", sans-serif;
  // letter-spacing: 2px;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--primary);
}

.main-canvas {
  padding: 1% 9% 1% 4%;
}
.pb_ele_header_description {
  color: $text-faded-color;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.pb_ele_header_heading {
  font-size: 30px;
  margin: 12px 0;
}

.palette .drag-item {
  background-color: $border-color;
  padding: 16px;
}

.inside {
  box-shadow: inset 0 0 26px 0.5px var(--primary);
}

.drag-item {
  border: red;
  background-color: inherit;
  color: inherit;
  cursor: move;
}

.drag-item:hover {
  /* background: #68717E1A 0% 0% no-repeat padding-box;
    border: 1px solid #68717E;
    opacity: 1; */
  opacity: 1;
  border: 1px solid #68717e;
  border-radius: 2px;
  border-color: $text-faded-color;
  background-color: $border-color;

  .actions {
    display: block;
  }
}

.dropzone {
  background: none;
  border-top: none;
  border-bottom: none;
  padding: 0 25%;
  border-color: $text-faded-color;
}

.ribbon {
  background-color: $border-color;
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  padding: 4px 8px;
  margin: 2px 6px;

  &:empty {
    display: none;
  }

  &.primary {
    background-color: var(--primary);
  }

  &.large {
    font-size: 12px;
    padding: 6px 14px;
  }

  &.fullWidth {
    font-size: 12px;
    width: 100% !important;
    padding: 16px 14px;
    margin: 10px 6px;
  }
}

.palette {
  background-color: inherit;
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  padding: 4px 8px;
  margin: 2px 6px;

  &:empty {
    display: none;
  }

  &.primary {
    background-color: var(--primary);
  }

  &.large {
    font-size: 12px;
    padding: 6px 14px;
  }

  &.fullWidth {
    font-size: 12px;
    width: 100% !important;
    padding: 16px 14px;
    margin: 10px 6px;
  }
}

.ribbon {
  background-color: $border-color;
  border-radius: 2px;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  padding: 4px 8px;
  margin: 2px 6px;

  &:empty {
    display: none;
  }

  &.primary {
    background-color: var(--primary);
  }

  &.large {
    font-size: 12px;
    padding: 6px 14px;
  }

  &.fullWidth {
    font-size: 12px;
    width: 100% !important;
    padding: 16px 14px;
    margin: 10px 6px;
  }
}

hr {
  background-color: $tab-border-color;
  height: 1px;
  width: 100%;
  border: none;
  margin: 16px 0px;
}

.text-faded {
  color: $text-faded-color;
}

.uppercase {
  text-transform: uppercase;
}

.info-placeholder-wrapper {
  // position: sticky;
  top: 65px;
}

.info-placeholder {
  box-sizing: content-box;
  // border: 1px solid $text-faded-color;
  background-color: #091724;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 100%;
  border-radius: 3px;
  margin-bottom: 16px;

  &.small {
    height: 250px;
  }

  &.fluid {
    height: calc(50% - 16px);
  }

  span {
    color: $text-faded-color;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.top-image-wrapper {
  width: 100%;
  height: 100px;

  &.top-image-wrapper-for-teams {
    height: 75px;
  }
  overflow: visible;

  .top-image {
    width: 100%;
    height: 375px;
    background-size: cover;
    background-position: center center;
    display: flex;
    justify-content: flex-end;

    &.top-image-for-teams {
      height: 376px;
    }

    button {
      margin: 24px 0px;
      margin-right: 12px;

      &:last-child {
        margin-right: 24px;
      }
    }
  }
}

img.game-icon {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

img.players-game-icon {
  width: 31px;
  object-fit: contain;
  margin-right: 1rem;
}

.narrow-content,
.less-narrow-content {
  max-width: 650px;
  width: 100%;
  margin: 0px auto 50px;
  line-height: 1.5rem;

  h1 {
    margin-top: 0px;
    padding-top: 16px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }

  p {
    color: $text-faded-color;
    font-size: 12px;
    line-height: 20px;
  }
}

.bold {
  font-weight: bold;
}

ul.list {
  list-style: none;

  li {
    border-bottom: 1px solid $tab-border-color;
    padding: 12px 8px;

    &:last-child {
      border-bottom: none;
    }
  }

  &.bordered {
    li {
      border-bottom: 1px solid $tab-border-color;
      padding: 12px 8px;

      &:last-child {
        border-bottom: 1px solid $tab-border-color;
      }

      &:first-child {
        border-top: 1px solid $tab-border-color;
      }
    }
  }
}

.less-narrow-content {
  max-width: 860px;
}

.primary-link {
  color: var(--primary);
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  background: none;
  border: 0;
  display: inline-block;
  text-align: left;
  cursor: pointer;
}

.bg-faded {
  background-color: $bg-faded;
}

.pill {
  background-color: $tab-border-color;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  color: $white;
  padding: 4px 12px;
  border-radius: 30px;
  font-size: 12px;
  line-height: 18px;
  margin-left: 12px;
  display: inline-block;

  img {
    vertical-align: middle;
    margin-right: 6px;
  }

  &.small {
    padding: 2px 6px;
  }

  &.success {
    background-color: $success;
  }

  &.primary {
    background-color: var(--primary);
  }

  &.warning {
    background-color: $orange;
  }

  &.danger {
    background-color: $danger;
  }

  &.danger-secondary {
    background-color: $danger-secondary;
  }

  &.large {
    line-height: 22px;
    font-size: 18px;
    margin: 0px;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 8px 12px;
    border-radius: 2px;
    margin: 10px 0px;
  }

  &.small {
    line-height: 10px;
    font-size: 8px;
    padding: 4px 8px;
  }

  &.frontend-result-row {
    margin: 0px 10px !important;
    font-size: 11px;
    flex: 0 0 18%;
    padding: 6px 0px;
    margin-bottom: 12px !important;
  }

  &.round {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    margin: 0px 3px;

    &.size-75 {
      width: 12px !important;
      height: 12px !important;
      padding: 0;
    }

    &.size-50 {
      width: 8px !important;
      height: 8px !important;
      padding: 0;
    }
  }

  &.bordered {
    background-color: $content-bg-color;

    &.success {
      border: 1px solid $success;
    }

    &.primary {
      border: 1px solid var(--primary);
    }

    &.warning {
      border: 1px solid $orange;
    }

    &.danger {
      border: 1px solid $danger;
    }

    &.danger-secondary {
      border: 1px solid $danger-secondary;
    }
  }

  &.round-edge {
    border-radius: 2px;
  }
}

.panel {
  @extend .bg-faded;

  border: 1px solid $tab-border-color;
  border-radius: 2px;
  margin: 6px 0px;

  header,
  main,
  footer,
  .panel-header,
  .panel-body,
  .panel-footer {
    padding: 15px 0px;
    width: 100%;
  }

  main,
  .panel-body {
    padding: 15px 15px;
  }

  header,
  .panel-header {
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    font-size: 0.85rem;
    border-bottom: 1px solid $tab-border-color;
  }

  footer,
  .panel-footer {
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    font-size: 0.7rem;
    border-top: 1px solid $tab-border-color;
  }

  &.secondary {
    border-radius: 0px;

    background-color: $header-bg-color;

    header,
    .panel-header {
      @extend .bg-faded;

      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
    }

    .secondary-items {
      padding: 15px 15px;
      margin-bottom: 0px;

      .col-1,
      .col-2,
      .col-3,
      .col-4,
      .col-5,
      .col-6 {
        display: flex;
        align-items: center;

        &.right {
          justify-content: flex-end;
        }
      }

      img {
        margin-right: 10px;
      }

      .team-member-image {
        width: 36px;
        height: 36px;
        border-radius: 18px;
      }

      .team-position-image {
        width: 16px;
        height: 16px;
      }
    }
  }
}

.bg-success {
  background-color: $success-bg-color;
}

.bg-danger {
  background-color: $danger-bg-color;
}

.bg-secondary {
  background: #333;
}

img.block-image {
  display: block;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 2px;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

select.select {
  background-color: $border-color;
  border: none;
  padding: 10px;
  width: 100%;
  margin: 6px 0px;
  font-weight: normal;
  text-transform: none;
  color: $white;
}

.border-right {
  border-right: 1px solid $border-color;
}

.border-left {
  border-left: 1px solid $border-color;
}

.border-top {
  border-top: 1px solid $border-color;
}

.border-bottom {
  border-bottom: 1px solid $border-color;
}

::-webkit-scrollbar {
  display: none !important;
}

.text-decoration-none {
  text-decoration: none;
}
.text-8 {
  font-size: 8px;
  line-height: 10px;
}

.text-10 {
  font-size: 10px;
  line-height: 13px;
}

.text-12 {
  font-size: 12px;
  line-height: 15px;
}

.text-14 {
  font-size: 14px;
  line-height: 18px;
}

p.text-14 {
  line-height: 20px;
}

.text-16 {
  font-size: 16px;
  line-height: 19px;
}

.text-18 {
  font-size: 18px;
  line-height: 22px;
}

.text-22 {
  font-size: 22px;
  line-height: 27px;
}

.text-32 {
  font-size: 32px;
  line-height: 39px;
}

a.link {
  color: var(--primary);
  text-decoration: none;
}

.scroll-gradient {
}

.color-primary {
  color: var(--primary);
}
.color-success {
  color: $success;
}

.color-danger {
  color: $danger;
}

.color-secondary-red {
  color: $secondary-red;
}

.color-accent-red {
  color: $accent-red;
}

.color-orange {
  color: $orange;
}

.color-white {
  color: $white;
}

.simple-tabs-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  button,
  a {
    margin-top: -24px;
  }
}

.faded-box-item {
  background-color: $sidebar-bg-color;
  &.darkLine {
    background-color: #0f1116;
  }
  &.matchMatricsHeadings {
    .headtitles {
      color: $text-faded-color;
    }
  }

  border: 1px solid $border-color;
  padding: 16px;
  margin: 4px 0px;

  &.no-padding {
    padding: 0px;
  }

  .inner {
    padding: 16px;
  }
}

.danger-box-item {
  background-color: #261515;
  //border: 1px solid $border-color;
  padding: 16px;
  margin: 4px 0px;
}

.success-box-item {
  background-color: #1a2b20;
  //border: 1px solid $border-color;
  padding: 16px;
  margin: 4px 0px;
}

.flex-row {
  display: flex;
  align-items: center;

  &.center {
    justify-content: center;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.center {
    align-items: center;
  }
}

.opacity-50 {
  opacity: 0.5;
}

.contains-card {
  padding-bottom: 26px;
}

.primary-bg-link {
  font-size: 14px;
  line-height: 18px;
  display: block;
  width: 100%;
  background-color: transparentize($primary, 0.9);
  color: var(--primary);
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;

  span {
    display: flex;
    align-items: center;
  }

  span .icon {
    margin-right: 12px;
  }

  .icon img {
    width: 17px;
    height: 17px;
  }
}

.discord-icon {
  mask: url(../img/discord-icon.svg);
  -webkit-mask: url(../img/discord-icon.svg) no-repeat center;
}
.external-primary {
  mask: url(../img/external-primary.svg);
  -webkit-mask: url(../img/external-primary.svg) no-repeat center;
}
.svg-icon {
  height: 17px;
  width: 17px;
  background-color: var(--primary);
  margin-right: 12px;
  font-weight: 700;
}

.image-40 {
  width: 40px;
  height: 40px;
}
.image-55 {
  width: 55px;
  height: 55px;
}

.image-32 {
  width: 32px;
  height: 32px;
}

.flex-list-item {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    display: flex;
    align-items: center;

    &.right {
      justify-content: flex-end;
    }
  }
}

.list-header {
  font-size: 12px;
  line-height: 15px;
  color: $text-faded-color;
  text-transform: uppercase;
  font-weight: bold;
  padding-left: 8px;
  padding-right: 8px;
}

.rules-row {
  font-size: 12px;
  line-height: 18px;
  color: $text-faded-color;

  h1,
  h2,
  h3,
  h4 {
    text-transform: uppercase;
    font-weight: bold;
    margin: 0px;
    color: white;
  }

  h1 {
    font-size: 16px;
    line-height: 19px;
    padding-top: 20px;
    padding-bottom: 6px;
  }

  h2 {
    font-size: 14px;
    line-height: 16px;
    padding-top: 20px;
    padding-bottom: 6px;
  }

  h3 {
    font-size: 12px;
    line-height: 16px;
    padding-top: 14px;
    padding-bottom: 4px;
  }

  h4 {
    font-size: 11px;
    line-height: 16px;
    padding-top: 12px;
    padding-bottom: 4px;
  }

  .simple-card {
    h3 {
      font-size: 14px;
      line-height: 16px;
    }

    color: $white;
  }
}

.social-links {
  img {
    margin-right: 10px;
    width: 46px;
    height: 46px;
  }
}

img.flag {
  display: inline-block;
  vertical-align: middle;
  width: 22px;
  // height: 12px;
  margin-right: 8px;
}

.flex-row-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.round-img-item {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: inline-block;
  margin-right: 5px;
  // border: 1px solid $hr-color;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: $white;
  overflow: hidden;

  &.primary {
    border: none;
    background-color: var(--primary);
  }

  .img {
    width: 100%;
    height: 100%;
  }
}
.__react_component_tooltip {
  font-size: 11px !important;
  font-weight: normal !important;
}
.team-list-tooltip {
  padding-left: 0;
  list-style: none;
}
.team-list-tooltip li {
  font-size: 11px;
  margin-bottom: 5px;
}
.team-list-tooltip li:last-child {
  margin-bottom: 0;
}
.border-radius-100 {
  border-radius: 100%;
}

/*
* BELOW HERE Is CODE WHICH NEEDS TO BE DELETED SOME DAY!
*/

.info-placeholder {
  // background: url("https://leagues-static.s3.eu-west-2.amazonaws.com/img/Player+Page+Ad+-+Large.jpg");
  background: none;
  background-size: contain !important;
  background-position: center;
  font-size: 0px;

  // &.small {
  //   background: url("https://leagues-static.s3.eu-west-2.amazonaws.com/img/Player+Page+Ad+-+Small.jpg");
  // }
}

.news {
  .info-placeholder:first-of-type {
    background: none;
    // background: url("https://leagues-static.s3.eu-west-2.amazonaws.com/img/Front+Page+Ad+-+Article+1.jpg");
  }
}

/*#root {
  position: relative;
  overflow: hidden;
  height: 100vh;
  .App {
    filter: blur(5px);
    pointer-events: none;
    user-select: none;
    position: relative;
  }

  &:after {
    content: "Thursday morning are leagues.gg making updates, therefor the platform will be temporary down";
    display: inline-block;
    position: absolute;
    top: 400px;
    left: 0px;
    right: 0px;
    margin: auto;
    font-size: 20px;
    text-align: center;
    color: white;
    font-weight: bold;
    filter: blur(0px);
    backface-visibility: hidden;
    -webkit-filter: blur(0);
  }
}
*/

.under-contruction-all-page {
  position: relative;
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
}

.under-contruction-all-page-withtitle div {
  position: relative;
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
}

.under-contruction-all-page-withtitle:after {
  content: "This page is currently under construction";
  display: inline-block;
  filter: blur(0px) !important;
  position: absolute;
  top: 50%;
  left: 0px;
  right: 0px;
  margin: auto;
  font-size: 24px;
  text-align: center;
  color: white;
  font-weight: bold;
  backface-visibility: hidden;
  // -webkit-filter: blur(0px) !important;
}

.under-contruction {
  position: relative;

  .tabs-container {
    .tab-content {
      filter: blur(5px);
      pointer-events: none;
      user-select: none;
    }

    &:after {
      content: "This page is currently under construction";
      display: inline-block;
      position: absolute;
      top: 400px;
      left: 0px;
      right: 0px;
      margin: auto;
      font-size: 24px;
      text-align: center;
      color: white;
      font-weight: bold;
      filter: blur(0px);
      backface-visibility: hidden;
      -webkit-filter: blur(0);
    }
  }

  &.no-tabs-under {
    .no-tabs {
      filter: blur(5px);
      pointer-events: none;
      user-select: none;
    }

    &:after {
      content: "This page is currently under construction";
      display: inline-block;
      position: absolute;
      top: 400px;
      left: 0px;
      right: 0px;
      margin: auto;
      font-size: 24px;
      text-align: center;
      color: white;
      font-weight: bold;
      filter: blur(0px);
      backface-visibility: hidden;
      -webkit-filter: blur(0);
    }
  }
}

.opacity_50 {
  opacity: 0.5;
}
// .blurry,
// .slider-container .slider-items .item,
// .slider-item {
//   filter: blur(2px);
//   pointer-events: none;
//   user-select: none;
// }

// USMAN FAISAL STARTED ADDING STYLES FROM HERE

.text-dark {
  color: #000000;
}

.text-white {
  color: #ffffff;
}

.bg-secondary {
  background-color: #0b0e12;
}

.bg-dark {
  background-color: #131519;
}

.rounded {
  border-radius: 0.5rem !important;
}

.fade {
  transition: opacity 0.15s linear;
}

.shadow-sm {
  box-shadow: 0 0 0.25rem #ffffff22;
}

.team-overlay {
  border: 1px solid #777777;
  border-radius: 10px;
}

.align-items-center {
  align-items: center;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-between {
  justify-content: space-between;
}

.light {
  font-weight: lighter !important;
}

.text-grey {
  color: #777777;
}

.border-all {
  background-color: transparent;
  border: 1px solid $border-color;
}

.visibility-hidden {
  visibility: hidden;
}

.d-none {
  display: none;
}

.profile-img-with-inital-1-letter {
  width: 30px;
  height: 30px;
  background: #666;
  border-radius: 100%;
  padding: 5px 0 0 10px;
}
.server-link-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .server-icon-name {
    display: flex;
    justify-content: flex-start;
    .server-name {
      display: none;
      @media (min-width: 1830px) {
        display: inline;
      }
    }
    .icon {
      margin-right: 5px;
    }
  }
}
// custom select
.custom-select {
  background-color: transparent;
  position: relative;
  border-radius: 5px;
  border: 1px solid #272729;
}
.custom-select:after {
  content: "";
  background-image: url("../img/down.svg");
  width: 10px;
  height: 10px;
  position: absolute;
  background-repeat: no-repeat;
  background-size: 100%;
  right: 15px;
  top: 50%;
  margin-top: -3px;
  opacity: 0.5;
}
.custom-select select {
  appearance: none;
  background-color: transparent;
  color: #575e6a;
}
.custom-select select.no_role {
  color: #575e6a;
}
.custom-select select.with_role {
  color: #68717e;
}
select.text-white {
  color: white;
}
.remove-icon {
  color: #404146;
  cursor: pointer;
}
.brackets-wrapper {
  position: relative;
}
.brackets-wrapper:before {
  position: absolute;
  width: 100px;
  height: 100%;
  pointer-events: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  z-index: 1;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgb(3, 6, 13) 90%);
}

.pr-30 {
  padding-right: 30px;
}
.pl-30 {
  padding-left: 30px;
}
.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}
.ant-switch {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: 44px;
  height: 22px;
  line-height: 22px;
  vertical-align: middle;
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  border-radius: 100px;
  cursor: pointer;
  transition: all 0.2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.ant-switch-checked:focus {
  box-shadow: 0 0 0 2px #e6f7ff;
}
.ant-switch:focus:hover {
  box-shadow: none;
}
.ant-switch-checked {
  background-color: #1890ff;
}
.ant-switch-loading,
.ant-switch-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.ant-switch-loading *,
.ant-switch-disabled * {
  box-shadow: none;
  cursor: not-allowed;
}
.ant-switch-inner {
  display: block;
  margin: 0 7px 0 25px;
  color: #fff;
  font-size: 12px;
  transition: margin 0.2s;
}
.ant-switch-checked .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-handle {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 18px;
  height: 18px;
  transition: all 0.2s ease-in-out;
}
.ant-switch-handle::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: "";
}
.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 2px);
}
.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-loading-icon.anticon {
  position: relative;
  top: 2px;
  color: rgba(0, 0, 0, 0.65);
  vertical-align: top;
}
.ant-switch-checked .ant-switch-loading-icon {
  color: #1890ff;
}
.ant-switch-small {
  min-width: 28px;
  height: 16px;
  line-height: 16px;
}
.ant-switch-small .ant-switch-inner {
  margin: 0 5px 0 18px;
  font-size: 12px;
}
.ant-switch-small .ant-switch-handle {
  width: 12px;
  height: 12px;
}
.ant-switch-small .ant-switch-loading-icon {
  top: 1.5px;
  font-size: 9px;
}
.ant-switch-small.ant-switch-checked .ant-switch-inner {
  margin: 0 18px 0 5px;
}
.ant-switch-small.ant-switch-checked .ant-switch-handle {
  left: calc(100% - 12px - 2px);
}
.ant-switch-rtl {
  direction: rtl;
}
.ant-switch-rtl .ant-switch-inner {
  margin: 0 25px 0 7px;
}
.ant-switch-rtl .ant-switch-handle {
  right: 2px;
  left: auto;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle::before {
  right: 0;
  left: -30%;
}
.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle::before {
  right: -30%;
  left: 0;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
  margin: 0 7px 0 25px;
}
.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 18px - 2px);
}
.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
  right: calc(100% - 12px - 2px);
}

.ant-form-item-has-feedback .ant-switch {
  margin: 2px 0 4px;
}