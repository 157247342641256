.tournaments-stats-overview {
  background-color: $bg-faded;
  border-radius: 2px;
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 15px;

  .mini-pie-chart {
    position: relative;
    height: 40px;
    width: 40px;
    margin-right: 12px;
    border-radius: 20px;
    background: conic-gradient(var(--primary) 0, var(--primary) 0%, darken($primary, 30%) 0, darken($primary, 30%) 100%);

    &::after {
      position: absolute;
      content: "";
      top: 8px;
      left: 8px;
      height: 24px;
      width: 24px;
      background-color: $bg-faded;
      border-radius: 25px;
    }
  }

  .col-3 {
    border-right: 1px solid $hr-color;
  }

  .col-3:last-child {
    border-right: none;
  }

  .profile-images {
    width: 50px;
    height: 50px;
    margin-right: 12px;
    border-radius: 100px;
    object-fit: cover;
  }

  .tournaments-stats-overview-flex-column {
    display: flex;
    align-items: center;

    &.center {
      justify-content: center;
    }

    > div.flex-column {
      display: flex;
      flex-direction: column;

      > div {
        margin: 2px 0px;
      }
    }
  }
}

.league-entries {
  .profile-images {
    width: 50px;
    height: 50px;
    margin-right: 8px;
    border-radius: 16px;
    object-fit: cover;
  }
}

.round-image {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 7px;
}