.team-member-list-item {
  img {
    margin-right: 12px;
  }

  .team-member-img {
    width: 35px;
    height: 35px;
    background-size: cover;
    background-position: 50% 50%;
    border-radius: 100%;
    margin-right: 15px;
    background-color: #999;
  }

  .align-items-centerd {

    align-items: center;
    display: grid;
  }

  .team-member-details div[class^="col-"] {
    display: flex;
    align-items: center;


    .team-member-info {
      display: flex;
      flex-direction: column;
    }

    .position-icon {
      width: 24px;
    }
  }

  .edit-teammem-role {
    color: var(--primary);
    background: none;
    cursor: pointer;
    border: 0;
    outline: 0;
    font-size: 1.2rem;
  }

  .team-member-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    div {
      display: flex;
    }

    .primary-link {
      width: 100%;
      text-align: right;
      white-space: nowrap;
      margin-left: 27px;
      outline: 0;
    }
  }
  .btn-outline-pimary {
    color: #3799d8;
    background-color: #091725;
  }
}

.no_profile {
  flex-shrink: 0;
  width: 130px;
  height: 130px;
  font-size: 13px;
  color: var(--primary);
  text-align: center;
  line-height: 35px;
  font-weight: 500;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 100%;
  margin-right: 15px;
}

.substituteSubRoleTypes {
  display: flex;
  flex-direction: column;
}

.icon-alignment {
  .sub-text-icon {
    margin-left: 33px;
  }

  .sub-icon {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.withoutSubRole {
  display: flex;
  align-items: center;
}

.subRole {
  margin-left: 35px;
}

.no-spec {
  line-height: 2em;
}
.input-width{
  width:75% !important;
}