form {
}

.form-row {
  display: block;
  position: relative;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &.inline {
    display: flex;

    :first-child {
      margin-right: 5px;
    }

    label {
      margin: 0;
    }
  }

  label {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-bottom: 7px;
    pointer-events: none;
    &.pointer-true {
      pointer-events: inherit;
    }

    a {
      color: var(--primary);
      text-decoration: none;
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .form-input-error {
    font-size: 0.8rem;
    margin-top: 7.5px;
    color: #e74c3c;
  }

  .toggle-pswd-visibility {
    position: absolute;
    right: 10px;
    top: 33px;
    cursor: pointer;
    user-select: none;
  }
}
textarea.form-input {
  resize: none;
}
input.form-input,
textarea.form-input,
select.form-input {
  display: block;
  position: relative;
  width: 100%;
  padding: 13.5px;
  background-color: $tab-border-color;
  border: 1px solid $tab-border-color;
  border-radius: 2px;
  color: white;
  outline: 0;
  font-size: 0.9rem;
  line-height: 15px;
  appearance: none;
  // height: 38px;
  transition: border 0.3s;

  border: 1px solid transparent;

  &:hover {
    border: 1px solid var(--primary);
  }

  &:focus {
    background-color: $header-bg-color;
    border: 1px solid var(--primary);
  }

  &.invalid {
    border: 1px solid $accent-red;
  }
}

select.form-input {
  text-transform: uppercase;
  font-weight: bold;
}

textarea.form-input {
  resize: none;
}

select.form-input {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNDUiIGhlaWdodD0iMjcuNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KIDwhLS0gQ3JlYXRlZCB3aXRoIE1ldGhvZCBEcmF3IC0gaHR0cDovL2dpdGh1Yi5jb20vZHVvcGl4ZWwvTWV0aG9kLURyYXcvIC0tPgogPGc+CiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPgogIDxyZWN0IGZpbGw9Im5vbmUiIGlkPSJjYW52YXNfYmFja2dyb3VuZCIgaGVpZ2h0PSIyOS41IiB3aWR0aD0iNDciIHk9Ii0xIiB4PSItMSIvPgogIDxnIGRpc3BsYXk9Im5vbmUiIG92ZXJmbG93PSJ2aXNpYmxlIiB5PSIwIiB4PSIwIiBoZWlnaHQ9IjEwMCUiIHdpZHRoPSIxMDAlIiBpZD0iY2FudmFzR3JpZCI+CiAgIDxyZWN0IGZpbGw9InVybCgjZ3JpZHBhdHRlcm4pIiBzdHJva2Utd2lkdGg9IjAiIHk9IjAiIHg9IjAiIGhlaWdodD0iMTAwJSIgd2lkdGg9IjEwMCUiLz4KICA8L2c+CiA8L2c+CiA8Zz4KICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+CiAgPGcgaWQ9InN2Z18xNiI+CiAgIDxsaW5lIHN0cm9rZS1saW5lY2FwPSJ1bmRlZmluZWQiIHN0cm9rZS1saW5lam9pbj0idW5kZWZpbmVkIiBpZD0ic3ZnXzE0IiB5Mj0iMjMuOTg2NDkzIiB4Mj0iMjQuMTE1NTE0IiB5MT0iMy40MjkxNjciIHgxPSIzLjU1ODE4OSIgc3Ryb2tlLXdpZHRoPSIzIiBzdHJva2U9IiM5OTk5OTkiIGZpbGw9Im5vbmUiLz4KICAgPGxpbmUgdHJhbnNmb3JtPSJyb3RhdGUoOTAgMzIuMzI0MDMxODI5ODMzOTksMTMuNzA3ODIwODkyMzMzOTg4KSAiIHN0cm9rZS1saW5lY2FwPSJ1bmRlZmluZWQiIHN0cm9rZS1saW5lam9pbj0idW5kZWZpbmVkIiBpZD0ic3ZnXzE1IiB5Mj0iMjMuOTg2NDkzIiB4Mj0iNDIuNjAyNjk1IiB5MT0iMy40MjkxNjciIHgxPSIyMi4wNDUzNjkiIHN0cm9rZS13aWR0aD0iMyIgc3Ryb2tlPSIjOTk5OTk5IiBmaWxsPSJub25lIi8+CiAgPC9nPgogPC9nPgo8L3N2Zz4=");
  background-size: 14px 8px;
  background-position: calc(100% - 12px) center;
  background-repeat: no-repeat;

  &::expand {
    display: none;
  }
}

input[type="checkbox"].form-input {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it
  cursor: pointer;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
    pointer-events: inherit;
  }

  // Box.
  & + label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
    width: 17px;
    height: 17px;
    border-radius: 2px;
    background: #777;
  }

  // Box focus
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // Box checked
  &:checked + label:before {
    background: white;
  }

  // Disabled state label.
  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  // Disabled box.
  &:disabled + label:before {
    box-shadow: none;
    background: #ddd;
  }

  // Checkmark. Could be replaced with an image
  &:checked + label:after {
    content: "";
    position: absolute;
    left: 3.25px;
    top: 8.5px;
    background: black;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 black, 4px 0 0 black, 4px -2px 0 black, 4px -4px 0 black, 4px -6px 0 black, 4px -8px 0 black;
    transform: rotate(45deg);
  }
}

.upload-image-btn {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  background-color: $tab-border-color;
  border-radius: 3px;
  cursor: pointer;
  background-size: cover;
  background-position: 50% 50%;
  &:hover {
    outline: 1px solid var(--primary);
  }
}

.upload-image-btn-cropped {
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  background-color: $tab-border-color;
  border-radius: 84px;
  cursor: pointer;
  background-size: cover;
  background-position: 50% 50%;
  &:hover {
    outline: 1px solid var(--primary);
  }
}

input[type="range"].form-input {
  -webkit-appearance: none;
  border: 0;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    background: #444;
    height: 5px;
    border-radius: 1px;
  }

  &::-moz-range-track {
    background: #444;
    height: 5px;
    border-radius: 1px;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 17px;
    width: 17px;
    background: var(--primary);
    margin-top: -6px;
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    height: 17px;
    width: 17px;
    background: var(--primary);
    margin-top: -6px;
    border-radius: 50%;
  }
}
