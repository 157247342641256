.main-item {
  margin-left: 5px;
  //padding-top: 15px;
  padding-right: 3px;
  border-bottom: 1px solid #22262e;
  //padding-bottom: 15px;
  margin-right: 20px;
  display: flex !important;
  align-items: center;
  transition: background-color .3s ease;
  transition: background-color .25s ease-out,width .25s ease-out,margin .25s ease-out,padding .25s ease-out;
  -ms-transition: background-color .25s ease-out,width .25s ease-out;
  &:hover {
    background-color: #0b0c0f;
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 2.5rem !important;
    padding-right: 23px !important;
  }
  &:active {
    background-color: #232730;
  }
  .set_players_button-disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .arrow-icon {
    path {
      stroke: var(--primary);
    }
  }
  .linking{
    display: contents;
    .link-div{
    padding-top:20px !important ;
    padding-bottom:20px !important ;

    
    }
  }
}
.match-item-hover {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 1.5rem !important;
    padding-right: 23px !important;
    &.date-div {
      padding-left: 1.5rem !important;
      padding-right: 2.1rem !important;

    }
    &.dateListing {
      padding-left: 1.5rem !important;
      padding-right: 1.25rem !important;
    }
}

.hours {
  font-size: 30px;
  font-weight: bolder;
  display: inline-block;
  line-height: 26px;
  height: 25px;
  overflow: hidden;
}
.mins {
  font-size: 12px;
  font-weight: bold;
  display: inline-block;//
  line-height: 14px;//
}
.below-text {
  font-size: 10px;
  color: #777;
  line-height: 9px;//
  display: inline-block;//
  letter-spacing: 0.4px;
}

.align-end {
  width: 100%;
  align-items: flex-end;
}

.min-zone {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  justify-content: space-between;

}
.live-broad {
  margin-left: 10px;
  border-radius: 20px;
  align-items: baseline;
  padding: 5px;
}
.live-broad.live {
  background-color: rgba(241, 24, 85, 0.1);
  color: #f11857;
}
.live-broad.broad {
  background-color: rgba(143, 163, 177, 0.1);
  color: #8fa3b1;
}
.dot {
  height: 7px;
  width: 7px;
  border-radius: 100%;
  margin: 3px;
}
.text-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.bottom-float {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
  text-align: center;
  border-radius: 30px;
  width: 100px;
  padding: 10px;
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
}

.league-best {
  flex-direction: column;
  align-content: flex-end;
  justify-content: center;
  text-align: end;
}
.vs {
  color: #68717e;
  text-align: center;
  width: 70px;
}
.inactiveLink {
  pointer-events: none;
  cursor: default;
}
.place {
  color: #8fa3b1;
}
.row-custom {
  display: flex !important;
  // flex-wrap: wrap;
  // margin-right: -30px;
  // margin-left: -30px;
  justify-content: center;
}
.imgLogoWidth {
  max-width: 45px;
  min-width: 45px;
}
.imgLogo {
  margin-right: 15px;
  // margin-left: 15px;
}
.imgLogoContainer{
  min-width: 45px;
  display: flex;
  align-items: center;
  max-width:45px;
}
.imgLogoCropped {
  border-radius: 80px;
}

.scrimMatch {
  display: contents;
}
.code-align{
 max-width: 12% !important;
 
}
.code-btn{
width: 100%;
padding-left: 2rem !important;
}