// @mixin expanding-animation-mixin() {
//   @keyframes expanding {
//     from {
//       height: 0px;
//       opacity: 0;
//     }
//     to {
//       height: max-content;
//       opacity: 1;
//     }
//   }
// }

// @include expanding-animation-mixin();

.tabs-container {
  .tabs-nav {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $tab-border-color;
    margin-top: 1em;

    &.teams-tab {
      scroll-behavior: smooth;
      overflow-x: auto;
      &::-webkit-scrollbar {
        height: 0.21875em;
        cursor: pointer;
      }
      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &:hover::-webkit-scrollbar-thumb {
        background-color: var(--primary);
      }

      nav {
        display: flex;
        margin-left: 2px;
        padding-bottom: 0em;
        button {
          min-width: max-content;
          white-space: nowrap;
        }
        position: relative;
      }
    }

    button {
      position: relative;
      &:not(.mustAButton) {
        border: none;
        outline: none;
        font-family: $font-stack;
        text-transform: uppercase;
        font-weight: bold;
        text-decoration: none;
        margin-right: 30px;
        color: $tab-link-unactive;
        font-size: 14px;
        display: inline-block;
        padding: 0em 0.3125em;
        padding-bottom: 1.125em;
        background: none;
        cursor: pointer;
      }
      &.addNewPageBtn {
        color: var(--primary);
      }

      a {
        text-decoration: none;
      }

      &.active {
        color: $white;
        //border-bottom: 2px solid var(--primary);
      }

      &:hover {
        color: $white;
      }
    }
    .frontpage {
      margin-top: -15px !important;
      padding-bottom: 0px !important;
    }
    .filters {
      color: $tab-link-unactive;
      text-transform: uppercase;
      font-weight: bold;
      padding-bottom: 0.625em;
      margin-top: -1.25em;
      display: flex;
      align-items: center;

      > span {
        margin-right: 12px;
        width: 150px;
      }

      a {
        text-decoration: none;
        font-size: 14px;
      }
    }
  }
  .tab-content {
    padding-top: 1.875em;
    transition: all ease-out 0.2s 0.2s;
    div {
      animation: expanding 0.2s linear;
    }
    .tabs-container .tabs-nav {
      margin-top: -2px !important;
    }
  }
  .sub-tabs {
    border-bottom: none !important;
    button {
      height: 1.875em !important;
    }
  }
}
.filters-header {
  //padding-left: 20px;
  padding-top: 1.25em;
  position: sticky;
  top: 0; 
  z-index: 500;
  background-color: #12141A;
}

.tabs-container-1 {
    // padding-left: 45px;
    // padding-right: 45px;
  .teams-tab-container {
    scroll-behavior: smooth;
    overflow-x: auto;
    padding-bottom: 0.3125em;

    &::-webkit-scrollbar {
      height: 0.21875em;
      cursor: pointer;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &:hover::-webkit-scrollbar-thumb {
      background-color: var(--primary);
    }

    .tabs-nav {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid $tab-border-color;
      margin-top: 1em;

      // &.teams-tab{
      // scroll-behavior: smooth;
      // overflow-x: auto;
      // &::-webkit-scrollbar {
      //   height: 3.5px;
      //   cursor: pointer;
      // }
      // &::-webkit-scrollbar-thumb {
      //   background-color: transparent;
      // }
      // &::-webkit-scrollbar-track {
      //   background-color: transparent;
      // }
      // &:hover::-webkit-scrollbar-thumb {
      //   background-color: var(--primary);
      // }

      // }

      nav {
        display: flex;
        margin-left: 2px;
        padding-bottom: 0em;
        button {
          min-width: max-content;
          white-space: nowrap;
        }
        position: relative;
      }

      button {
        position: relative;
        &:not(.mustAButton) {
          border: none;
          outline: none;
          font-family: $font-stack;
          text-transform: uppercase;
          font-weight: bold;
          text-decoration: none;
          margin-right: 30px;
          color: $tab-link-unactive;
          font-size: 14px;
          display: inline-block;
          padding: 0px 5px;
          padding-bottom: 1.125em;
          background: none;
          cursor: pointer;
        }
        &.addNewPageBtn {
          color: var(--primary);
        }

        a {
          text-decoration: none;
        }

        &.active {
          color: $white;
          //border-bottom: 2px solid var(--primary);
        }

        &:hover {
          color: $white;
        }
      }
      .frontpage {
        margin-top: -0.9375em !important;
        padding-bottom: 0em !important;
      }
      .filters {
        color: $tab-link-unactive;
        text-transform: uppercase;
        font-weight: bold;
        padding-bottom: 0.625em;
        margin-top: -1.25em;
        display: flex;
        align-items: center;

        > span {
          margin-right: 12px;
          width: 150px;
        }

        a {
          text-decoration: none;
          font-size: 14px;
        }
      }
    }
  }
  .tab-content {
    padding-top: 30px;
    transition: all ease-out 0.2s 0.2s;
    div {
      animation: expanding 0.2s linear;
    }
    .tabs-container .tabs-nav {
      margin-top: -2px !important;
    }
  }
  .sub-tabs {
    border-bottom: none !important;
    button {
      height: 30px !important;
    }
  }
}

@media (max-width: 1400px) {
  .tabs-container {
    .tabs-nav {
      button {
        font-size: 0.8rem;
        margin-right: 10px;
      }
      .filters,
      .fitlers a {
        font-size: 0.8rem;
      }
    }
  }
  .tabs-container-1 {
    //padding-left: 45px;
    //padding-right: 45px;
    .teams-tab-container {
      .tabs-nav {
        button {
          font-size: 0.8rem;
          margin-right: 10px;
        }
        .filters,
        .fitlers a {
          font-size: 0.8rem;
        }
      }
    }
  }
}
.tabs-bg-dark {
  background-color: #000;
}
.tabs-container .tabs-nav button::after {
  border-bottom: 2px solid var(--primary);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.tabs-container-1 .teams-tab-container .tabs-nav button::after {
  border-bottom: 2px solid var(--primary);
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  -webkit-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  -webkit-transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tabs-container .tabs-nav .active::after {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.tabs-container-1 .teams-tab-container .tabs-nav .active::after {
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.participation-card-main .true {
  opacity: 1 !important;
}
@media (min-width:1940px) and (max-width:2132px)
 {
  .tabs-container-1 .teams-tab-container .tabs-nav button:not(.mustAButton) {
    padding-bottom: 1em !important;
}
 }