.tournament-item {
	max-width: 368px !important;
	flex: 0 0  368px !important;
	span.tournament-item-image-inner {
		&.orange {
			background-color: $orange;
		}
		&.red {
			background-color: $danger;
		}
		.icon {
			vertical-align: middle;
			margin-right: 4px;
		}
	}

	.item-excerpt {
		color: $text-grey-color;
		overflow: visible;
		-webkit-line-clamp:initial;
		.tournament-item-datetime {
			color: $text-faded-color;
		}
	}
}
