@import "./TournamentsSingleTabs/Brackets/brackets";
@import "./TournamentsSingleTabs/Participants/participants";

.page-with-tabs {
    .page-with-tabs-image-container {
        img {
            // border-radius: 50px;
            border-radius: 0;
        }
    }
}