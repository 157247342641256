
  /* Switch Button */
  /* The slider */
  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    /* height: 20px; */
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 11px;
    width: 11px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #2196F3;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(19px);
    -ms-transform: translateX(19px);
    transform: translateX(19px);
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }

  .ant-space-item .ant-switch {
    min-width: 41px;
    height: 22px;
  }
  .ant-space-item .ant-switch-checked:focus {
    box-shadow: 0 0 0 0px ;
}
/* For Stages Label */
.check-type1 .check-label {
  /* padding-top: 12px; */
  font-size: 14px;
  border: none;
  margin-right: 10px;
}
/* .checkbox-container .check-type1  {
  border: none;
    width: 18px;
    height: 18px;
    margin: auto;
    background-color: rgba(62, 171, 240, 0.1);
    margin-right: 10px;
    margin-left: 10px;
} */
.multi-stages-matches {
  /* margin-right: 0px !important; */
}
.gray-down-dropdown {
  width: 15px;
  height: 7px;
  color: #ffff;
}