.collapsible-wrapper {
  background: #12141a;
  padding: 15px;
  border-radius: 2px;
  margin-bottom: 8px;
  li {
    list-style-type: none;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px 0 8px;
    border-top: 1px solid #22262e;

    &:last-child {
      padding-bottom: 0;
    }
  }
}
.align-items-centerd {
  align-items: center;
  display: grid;
}

.participant-types {
  a {
    display: inline-block;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: #656d7a;
    & + a {
      margin-left: 10px;
    }
    &.active {
      color: white;
    }
  }
}
.par-team-info {
  display: flex;
  align-items: center;
  cursor: default;
  .team-logo {
    width: 32px;
    height: 32px;
    background-size: cover;
    background-position: center;
  }
  .team-name {
    font-size: 16px;
    color: #ffffff;
    text-transform: uppercase;
    padding-left: 12px;
    font-weight: bold;
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.par-other-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: default;
  .par-info-inner {
    display: flex;
    cursor: default;
  }
  .par-num {
    font-size: 12px;
    color: white;
    font-weight: bold;
    background: #22262e;
    border-radius: 30px;
    // padding: 4px 10px;
    text-transform: uppercase;
    margin-right: 10px;
    width: auto;
    text-align: center;
    cursor: default;
    justify-content: center;
    // border-radius: 20px;
    // background: rgba(25, 219, 96, 0.1);
    padding: 7px 10px;
    display: flex;
    // margin-bottom: 5px;
  }
  .par-rank {
    font-size: 12px;
    color: white;
    font-weight: bold;
    background: #22262e;
    border-radius: 14px;
    padding: 4px 10px;
    margin-right: 10px;
    width: 125px;
    text-align: center;
    cursor: default;
  }

  .par-goto-team {
    cursor: pointer;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    margin-left: 10px;
    a {
      color: var(--primary);
      text-decoration: none;
      .icon {
        margin-left: 3px;
      }
    }
  }
}
.par-arrow {
  .icon {
    cursor: pointer;
    img {
      transition: transform 0.2s linear;
    }
  }
}
.is-open {
  .par-arrow {
    .icon {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
