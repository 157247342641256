.wide-page-with-tabs {
  .team-heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px 0px;

    .team-heading {
      display: flex;

      img.team-logo {
        width: 90px;
        height: 90px;
        margin-right: 22px;
        object-fit: contain;
      }

      .team-heading-text {
        display: flex;
        flex-direction: column;
      }

      h1 {
        text-transform: none;
        margin: 10px 0px;
        padding: 0px;
      }

      .text-faded {
        font-weight: 600;
      }
    }
  }

  button.wide-primary {
    margin: 32px 0px;
  }

  .col-player {
    width: 18%;
    margin: 0px 1%;
  }
}

.update-btn-container-rom {
  flex: auto;
  display: flex;
  justify-content: flex-end;

  >div {
    text-align: right;
  }

  .last-updated-text {
    display: flex;
    width: 40%;
    align-items: center;
    font-size: 0.7rem;
    color: #999;
  }
}

.ranked-solo {
  width: 12% !important;
  position: absolute !important;
  margin-left: 314px;
  margin-top: -8px;
}

// .in-small-gutter3 {
//   margin-left: 0 !important;
//   margin-right: 0 !important;
//   // flex-wrap: nowrap !important;
// }

.leagueHeading {
  border: none;
  outline: none;
  text-transform: uppercase;
  font-weight: bold;
  margin-right: 30px;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  padding: 0px 5px;
  padding-bottom: 18px;
  background: none;
}
.test-organization:hover svg{
  color: #296f9e;
  cursor: pointer;
}

.test-organization:hover .ng-m-t-10{
  color: #3eabf0;
}

.test-organization:hover .des-text{
  color: #296f9e;
}

.test-organization {
  height: 237px;
  border-radius: 10px;
  border: 2px dashed #22262e;
  &:hover {
    border: 2px dashed #3eabf0;
    background-color: #1b2837;
  }
  .ex-link {
    color: #68717e;
  }
  .flx-column {
    flex-direction: column;
    svg {
      // &:hover {
      // color: #296f9e;
      // cursor: pointer;
      // }
    }
  }
  .align-center {
    align-items: center;
  }
  .ng-m-t-10 {
    margin-top: -10px;
    // &:hover {
    //   color: #3eabf0;
    // }
  }
  .des-text {
    color: #68717e;
    // &:hover {
    //   color: #296f9e;
    // }
  }
}
.organization-contaier {
  font-weight: bolder;
  color: #68717e;
  display: flex;
  height: 100px;
  margin-top: 100px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  border: 1px dashed #22262e;
}
.o-c {
  .dynamic-search {
    .dynamic-search-input {
  input {
    &:hover {
      border: 1px solid #3eabf0 !important;
      background-color:  #1b2837 !important;
    }
    &:focus {
      border: 1px solid #3eabf0 !important;
    }
  }
}
}
}
.mt-40 {
  margin-top: 40px;
}
.d-flex {
  display: flex;
}
.justify-content-space-between {
  justify-content: space-between;
}
.pl-0 {
  padding-left: 0;
}
.pr-0 {
  padding-right: 0;
}
.mb-e-0 {
  margin-block-end: 0;
}