.steambutton {
    display: block;
    background-color: #6f9f31;
    width: 270px;
    height: 50px;
    line-height: 50px;
    margin: auto;
    color: #fff;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 5px;
  }
  
    // .add-images {
      .image-card {
        width: 100%;
        height: 125px;
        margin-bottom: 20px;
        background-color: rgba(255, 255, 255, 0.025);
        background-size: cover;
        background-position: center;
        border: 1px solid rgba(255, 255, 255, 0.15);
        border-radius: 3px;
        text-align: center;
        line-height: 125px;
        font-size: 1.1rem;
        font-weight: 600;
        text-transform: uppercase;
        cursor: pointer;

        &.round-logo {
          width: 125px;
          margin: auto;
          margin-bottom: 20px;
          border-radius: 100%;
        }
      }

      .next-buttons {
        display: flex;
        justify-content: space-between;
      }
    // }
  
  .customInput{background: black;
  color: white;
  border: 1px solid white;
  height: 38px;
  width: 100%;}
  .steambutton span {
    font-family: 'Exo 2', sans-serif;
    font-weight: bold;
    letter-spacing: 0.1em;
    width: 75%;
    font-size: 14px;
    text-transform: uppercase;
    left: 0;
    -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
  }
  
  .steambutton span,
  .steambutton .icon {
    display: block;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
  }
  
  .steambutton .icon .fa {
    font-size: 30px;
    line-height: 50px;
    -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4), height 0.25s ease;
  }
  
  .steambutton .icon {
    width: 25%;
    right: 0;
    -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
    transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
  }
  
  .steambutton span,
  .steambutton .icon {
    display: block;
    height: 100%;
    text-align: center;
    position: absolute;
    top: 0;
  }
  
  .fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
  }
  
  .steambutton span:after {
    content: '';
    background-color: #5d8628;
    width: 2px;
    height: 70%;
    position: absolute;
    top: 15%;
    right: -1px;
  }
  
  .steambutton.success span,
  .steambutton:hover span {
    left: -72%;
    opacity: 0;
  }
  
  .steambutton.success .icon,
  .steambutton:hover .icon {
    width: 100%;
  }
  
  .steambutton.success .icon .fa,
  .steambutton:hover .icon .fa {
    font-size: 45px;
  }


.riotsbutton{
    display: block;
    background-color: #d13639;
    width: 270px;
    height: 50px;
    line-height: 50px;
    margin: auto;
    color: #fff;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: 9999px;
    align-content: center;
    text-align: center;
    text-decoration: none;

}
.riotsbutton span {
  font-family: 'Exo 2', sans-serif;
  font-weight: bold;
  letter-spacing: 0.1em;
  width: 75%;
  font-size: 14px;
  text-transform: uppercase;
  -webkit-transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
  transition: all 0.25s cubic-bezier(0.31, -0.105, 0.43, 1.4);
}
.riotsbutton svg{
  vertical-align: text-bottom;
  margin-right: 5px;
}

.riotsbutton span,
.riotsbutton .icon {
  // display: block;
  height: 100%;
  text-align: center;
  
  top: 0;
}
.create-text{
  font-family: 'Exo 2', sans-serif;
  font-size: 12px;
  margin-bottom: 15px;
  
}
.create-text a {
  font-family: 'Exo 2', sans-serif;
  font-size: 12px;
  margin-left: 30px;
  color:blue;
  
}

  .create-text{
    font-family: 'Exo 2', sans-serif;
    font-size: 12px;
    margin-bottom: 15px;
    
  }
  .create-text a {
    font-family: 'Exo 2', sans-serif;
    font-size: 12px;
    margin-left: 30px;
    color:blue;
    
  }
  .lol-button  {
    background-color: rgb(62, 171, 240) !important;
  } 
  .lol-sel  {
    &:focus,
    &:active {
      border: 1px solid rgb(62, 171, 240) !important;
    }
    
  } 
  .csgo-button  {
  
    background-color:rgb(229, 92, 19) !important;
  } 
  .csgo-sel  {
  
    &:focus,
    &:active {
      border: 1px solid rgb(229, 92, 19) !important;
    }
    
  } 
  .vl-button {
    background-color: rgb(251, 69, 83) !important;
  }
  .vl-sel {
    &:focus,
    &:active {
      border: 1px solid rgb(251, 69, 83) !important;
    }
    
  }
