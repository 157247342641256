
  .Translation-dropdown {
    .dropdown-content {
      width: 400px !important;
    }
  }
  input{
    color: white;
    :focus-visible{
      border: none;
    }
  }
  input:focus-visible {
    outline: 2px solid var(--primary);
    border-radius: 3px;
}

.global-header .dropdown-wrapper main {
  padding: 0px;
}
.itemshover{
  :hover{
    background-color: rgb(28, 32, 41);
  }
}



