.dropdown-wrapper.show .dropdown-toggle {
  background-color: rgb(25, 27, 36) !important;
}

.dropdown-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .dropdown-dotted-button {
    position: relative;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    span {
      width: 4px;
      height: 4px;
      margin: 6px 2px;
      background-color: $text-faded-color;
      border-radius: 2px;
    }

    #dropdown-dotted-button-menu-box {
      display: none;
    }

    #dropdown-dotted-button-menu-box:checked + .dropdown-dotted-button-menu {
      display: block;
    }

    .dropdown-dotted-button-menu {
      position: absolute;
      right: -10px;
      top: 100%;
      width: 200px;
      padding: 12px;
      background-color: $header-bg-color;
      border: 1px solid $border-color;
      display: none;
      cursor: pointer;
      z-index: 9;

      div {
        margin-bottom: 12px;
        font-size: 12px;
        line-height: 15px;
        font-weight: normal;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .dropdown-toggle {
    position: relative;
    padding: 10px 12px;
    cursor: pointer;

    .gc-ddown {
      padding: 6px 12px;
    }
    .image {
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      background-size: cover;
      margin-right: 10px;
    }

    .icon img {
      vertical-align: middle;
    }

    span.label {
      font-size: 0.8rem;
      font-weight: bold;
    }

    .badge {
      position: absolute;
      background-color: var(--primary);
      padding: 2px 4px;
      border-radius: 2px;
      font-size: 8px;
      font-weight: bold;
      line-height: 10px;
      color: $white;
      top: 0px;
      right: 0px;
      transform: translate(-30%, 40%);

      &:empty {
        display: none;
      }
    }

    .caret {
      margin-left: 0px;

      .icon img {
        width: 8px;
        opacity: 0.5;
      }
    }
  }

  .dropdown-content {
    position: absolute;
    // display: none; //////
    // top: 100%;
    top: 100%; //////
    right: 0px;
    width: 230px;
    background-color: $header-bg-color;
    border-radius: 2px;
    border-top-right-radius: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-transition: height 0.05s linear;
    transition: height 0.05s linear;
    height: 0;
    opacity: 0;
  }
  .dropdown-content-animation {
    -webkit-transition: height 0.05s linear;
    transition: height 0.05s linear;
    height: 0;
    opacity: 0;
  }

  // .bottom-image-cover {
  //   position: absolute;
  //   transform: rotate(45deg);
  //   right: -160px;
  //   bottom: -160px;
  //   width: 200px;
  //   height: 200px;
  //   background-color: $content-bg-color;

  //   &.forGameSelector {
  //     background-color: transparent;
  //   }
  // }

  &.show {
    .dropdown-content {
      // display: block;
      height: auto;
      opacity: 1;

      .right-corner-dropdown {
        position: absolute;
        background-color: var(--primary);
        height: 28px;
        width: 1px;
        bottom: 7px;
        right: 16px;
        z-index: 1000;
        transform: rotate(47deg);
      }
    }
    .edge {
      // polygon(0 0, 100% 0, 100% calc(100% - 20px), calc(100% - 20px) 100%, 0 100%, 0 0)
      // clip-path: polygon(0% 0, 100% 0, 100% 88%, 92% 100%, 0 100%, 0% 50%);
      clip-path: polygon(0 0, 100% 0, 100% calc(100% - 19px), calc(100% - 20px) 100%, 0 100%, 0 0);
    }
    .dropdown-toggle {
      background-color: $header-bg-color; //$dropdown-bg;
      border-radius: 2px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
      z-index: 40;
    }
    .z_index {
      z-index: 39;
    }
  }

  &.dynamic-width {
    .dropdown-content {
      // width: auto;
      // max-width: 230px;
      // border: 1px solid var(--primary);
      // position: absolute;
      // border-top: none;
      // width: auto;
      // max-width: 196px;
      // z-index: 38;
      border: 0;
      position: relative;
      border-top: none;
      width: auto;
      max-width: 193px;
      z-index: 38;
      left: -19px;
      top: 0px;
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    border-bottom: 1px solid $border-color;

    h3 {
      font-size: 14px;
      line-height: 18px;
      margin: 0px;
      padding: 0px;
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  main {
    padding: 10px;

    .loaderForGamesSelection {
      margin: 13px 69px;
    }
  }
}

.gc-box {
  // margin-left:30px;
  .dropdown-toggle {
    padding: 5px 22px;
    margin-left: -17px;
    clip-path: polygon(0% 0, 100% 0, 100% 49%, 91% 100%, 0 100%, 0% 50%);
    // border-bottom-right-radius: 39px;
    // border-top-left-radius: 39px;
    width: 119%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      width: 63%;
    }
    // z-index: 40;
    height: 37px;
  }
  .dropdown-toggle1 {
    clip-path: none !important;
  }
  height: auto;
  width: 170px;
  position: absolute;
  // background-color: var(--primary);
  border-radius: 0px;
  z-index: 40;
  flex-direction: column;
  // top: 63px;
  justify-content: flex-start !important;
  align-items: flex-start;
  top: 11px;
  left: 250px;
  :hover {
    background-color: #191b24;
  }

  .gc-top,
  .gc-bottom,
  .gc-left,
  .gc-right,
  .gc-left-corner,
  .gc-right-corner {
    position: absolute;
    background-color: var(--primary);
  }

  .gc-top {
    // width: calc(100% - -6px);
    // height: 1px;
    // top: 0;
    // left: 10px;
    width: calc(100% - -4.5px);
    height: 1px;
    top: 0;
    left: 9.3px;
    z-index: 50;
  }
  .gc-bottom-content {
    position: absolute;
    background-color: #0e1117;
    height: 21px;
    width: 11px;
    bottom: -8px;
    right: -15px;
    transform: rotate(45deg);
    z-index: 42;
  }

  .gc-top-content {
    position: absolute;
    background-color: #0e1117;
    height: 28px;
    width: 11px;
    top: -8px;
    left: -9px;
    transform: rotate(45deg);
    z-index: 42;
  }

  .gc-bottom {
    // width: calc(100% - -5px);
    // height: 1px;
    // bottom: 0;
    // right: 4px;
    width: calc(100% - -5px);
    height: 1px;
    bottom: -1.4px;
    right: 4px;
    z-index: 50;
  }

  .gc-left {
    // height: calc(100% - 10px);
    // width: 1px;
    // bottom: -5px;
    // left: -10px;
    height: calc(100% - 16.5px);
    width: 1px;
    bottom: -1px;
    left: -9px;
    z-index: 50;
  }

  .gc-right {
    // height: calc(100% - 10px);
    // width: 1px;
    // top: -4px;
    // right: -16px;
    height: calc(100% - 17.5px);
    width: 1px;
    top: 0px;
    right: -14px;
    z-index: 50;
  }

  .gc-left-corner {
    // height: 28px;
    // width: 1px;
    // bottom: -9px;
    // right: -6px;
    // transform: rotate(47deg);
    // z-index: 37;
    height: 25px;
    width: 1px;
    top: -3.5px;
    left: 0px;
    transform: rotate(45deg);
    z-index: 50;
  }

  .gc-right-corner {
    // height: 29px;
    // width: 1px;
    // top: -9px;
    // left: 0px;
    // transform: rotate(45deg);
    // z-index: 50;
    height: 25px;
    width: 1px;
    bottom: -4px;
    right: -5px;
    transform: rotate(45deg);
    z-index: 50;
  }
}

.toggle-game {
  :hover {
    // transition: 0.3s ease-in-out;
    transition: background-color 0.4s ease-in-out;
  }
}
.empty {
  background-color: #0f1116;
  position: absolute;
  right: 0px;
  top: 15px;
  left: -9px;
  width: 194px;
  height: 15px;
  z-index: 36;
}
div::-webkit-scrollbar {
  display: block !important;
  width: 10px;
  z-index: 99;
}
div::-webkit-scrollbar-track {
  background-color: #191b24;
}
div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #22262e;
}

.dropdown-content-div {
  border: 2px red;
  display: contents;
  clip-path: polygon(0% 0, 100% 0, 100% 83%, 85% 100%, 0 100%, 0% 50%);
  .right-corner-dropdown {
    position: absolute;
    background-color: var(--primary);
    height: 28px;
    width: 1px;
    bottom: 7px;
    right: 16px;
    z-index: 1000;
    transform: rotate(47deg);
  }
}

.dropdown_menu-2{
  animation-name: example;
  animation-duration: 0.2s;
  
}
@keyframes example {
  0%   {max-height: 0px; max-width: 0px}
  100% {max-height: 400px;max-width: 400px}
}