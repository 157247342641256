.wide-page-with-tabs {
  .wide-page-with-tabs-heading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    //margin: 20px 0px 80px;
    &.organisations{
      //margin: 20px 0px 13px;
    }

    &.teams{
      //margin: 20px 0px 40px;
    }
    
    &.without_margin {
      margin: 0;
    }

    .wide-page-with-tabs-heading {
      display: flex;

      img.wide-page-with-tabs-logo {
        width: 104px;
        height: 104px;
        margin-right: 16px;
        object-fit: cover;
        border-radius: 100%;
      }

      img.wide-page-with-tabs-logo_withoutCropped {
        width: 104px;
        height: 104px;
        margin-right: 16px;
        object-fit: cover;
      }

      .wide-page-with-tabs-heading-text {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .labelForTagline {
          padding-top: 4px;
        }
      }

      h1 {
        font-size: 42px;
        line-height: 52px;
        text-transform: none;
        margin: 10px 0px;
        margin-top: 0px;
        padding: 0px;
      }

      .text-faded {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;

        img {
          width: 18px;
          height: 18px;
          margin-right: 4px;
        }
      }
    }
  }

  button.wide-primary {
    margin: 32px 0px;
  }

  .tabs_fix{
    //margin-top: 25px;
    //margin-top: 50px;

  }
}
