.grayboxListing{
  background-color: #03060D;
  margin-top: 30px;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 700px;
  
  // background-color: #12141a;
  // scroll-behavior: smooth;
  margin-left: -30px !important;
  margin-right: 10px;
  .main-item {
    &:hover {
      padding-left: 2.5rem !important;
    }
  }
  &.recentMatches{
    max-height: 800px;
  }
  &.activeMatch{
    margin-left: -60px !important;
    // margin-right: -30px;
  }

&::-webkit-scrollbar {
    display: block !important;
    width: 10px;
}
&::-webkit-scrollbar-track {
    background-color: #12141a;
}
&::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #22262e;
}
}
.graybox_yourmatches_hover {
  .main-item {
  &:hover{
    background-color: #000000ba;
  }}
}

.dateListing{
  border-bottom: 1px solid #22262e;
  // background-color: #12141A;
  // padding-left: 5px;
  // padding-top: 35px;
  line-height: 5.5em; //paading-top was causing line issue that's why linehieght
  padding-bottom: 10px;
  // margin-left: 5px;
  margin-right: 20px;
  height: 60px;
  // background: inherit;
  transition: background-color .25s ease-out,width .25s ease-out,margin .25s ease-out,padding .25s ease-out;
  -ms-transition: background-color .25s ease-out,width .25s ease-out;
}
.date-wrapper{
  position: sticky;
  top: 0;
  z-index: 10;
  background: inherit;
}

div::-webkit-scrollbar {
    display: block !important;
    width: 10px;
}
div::-webkit-scrollbar-track {
    background-color: #12141a;
    margin-right:60px !important;
}
div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #22262e;

}


div::-webkit-scrollbar {
    display: block !important;
    width: 10px;
}
div::-webkit-scrollbar-track {
    background-color: #12141a;
}
div::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #22262e;
}
.prevListing{
  cursor: pointer;
  z-index: 999;
  margin-right: 10px;

  svg{
    color: var(--primary) !important
  }
}
.tag-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

#Match_div::-webkit-scrollbar-thumb {
    border-radius: 9999px;
}
#Match_div::-webkit-scrollbar {
    width: 10px !important;
}
#Match_div::-webkit-scrollbar-track {
    background-color: #0F1519 !important;
}


.listing-item-hover {
    margin-left: 0px !important;
    margin-right: 0px !important;
    padding-left: 2.5rem !important;
    padding-right: 23px !important;
    &.date-div {
      padding-left: 2.5rem !important;
      padding-right: 2.1rem !important;

    }
    &.dateListing {
      padding-left: 2.5rem !important;
      padding-right: 1.25rem !important;
    }
}
.no-match-text-matches{
  display: flex;
  justify-content: center;
  margin-top: 10px;
  height: 570px; // total 700px of gray box, we need to subtract date size of past result and then sticky date size also
}
.tournament_match{
 .col-12{
  padding-right: 0 !important;
  #Match_div{
    margin-right: 0;
  }
}
}